<template>
  <div>
      <h2 style="padding-bottom: 15px">개인정보처리방침</h2>
      <h3 style="line-height: 40px">제1조 개인정보의 처리 목적</h3>
      <p>
        씨에이치 플래닛(이하 “회사”라 함)가 운영하는 “위셀페이 서비스” 및 부가서비스는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다
        <br/>
        1.홈페이지 회원가입 및 관리<br/>

        회원 가입의사 확인, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존, 서비스 제공 계약의 성립, 유지, 종료를 위한 본인 식별 및 실명확인, 각종 회원관리, 계약서 발송, 서비스 제공을 위한 각 결제수단 별 상점 사업자 정보 등록 등을 목적으로 개인정보를 처리합니다.
        <br/>
        2.민원사무 처리
        <br/>
        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
        <br/>
        3.재화 또는 서비스 제공
        <br/>
        서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.
        <br/>
        4.마케팅 및 광고에의 활용
        <br/>
        신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
      </p>
      <h3 style="line-height: 40px">제2조 개인정보의 항목 및 수집방법
      </h3>
      <p>
        1.개인정보 수집 항목
        <br/>
        ①회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 최소한의 개인정보를 필수항목 또는 선택항목으로 수집하고 있습니다.
        <br/>
        - 사업자 회원(법인/개인)
        <br/>
        필수항목 : 상호명, 사업자등록번호, 휴대폰번호, 사업자 구분, 업태/업종, 대표자 성함, 아이디, 비밀번호, 판매자 명, 이메일
        <br/>
        선택항목 : 홈페이지, 정산은행, 계좌번호, 예금주, 주소
        <br/>
        ②회사는 페이앱 서비스 및 부가서비스 이용 과정에서 업무제휴 및 정산을 위해 아래와 같은 계약서류들을요청합니다.<br/>
        - 사업자 회원(법인/개인) : 인감 날인된 계약서 2부, 사업자등록증 사본 1부, 신분증 또는 법인인감증명서 1부, 통장사본 1부<br/>

        ③서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
        - 접속 IP 정보, 쿠키, 서비스 이용 기록, 접속기록<br/>

        2.개인정보 수집방법<br/>

        -홈페이지(회원가입, 문의상담), 서면양식, 전화, 상담 게시판, 이메일, 팩스
        <br/>
        -업무제휴 계약을 체결한 제휴사로부터 고객이 제시하는 개인정보 수집
      </p>
      <h3 style="line-height: 40px">제3조 개인정보의 처리 및 보유 기간</h3>
      <p>
        회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
        <br/>
        1.홈페이지 회원가입 및 관리, 민원사무 처리, 재화 또는 서비스 제공, 마케팅 및 광고의 활용과 관련한 개인정보는 수집·이용에 관한 동의일로부터 지체 없이 파기까지 위 이용목적을 위하여 보유·이용됩니다.
        <br/>
        2.상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
        <br/>
        이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
        <br/>
        -계약 또는 청약철회 등에 관한 기록 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br/>
        보존기간: 5년
        <br/>
        -대금결제 및 재화 등의 공급에 관한 기록 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br/>
        보존기간: 5년
        <br/>
        -전자금융 거래에 관한 기록 보존 이유: 전자금융거래법
        <br/>
        보존기간: 5년
        <br/>
        -전자금융 거래에 관한 기록 보존 이유: 전자금융거래법
        <br/>
        보존기간: 5년
        <br/>
        -소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br/>
        보존기간: 3년
        <br/>
        -계약 또는 청약철회 등에 관한 기록 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br/>
        보존기간: 3년
        <br/>
        -표시·광고에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률
        <br/>
        보존기간: 6개월

      </p>
      <h3 style="line-height: 40px">제4조 개인정보의 제3자 제공에 관한 사항</h3>
      <p>
        위셀페이는 원칙적으로 이용자의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서 처리하며 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
        <br/>
        1.국세청
        <br/>
        -개인정보를 제공받는 자: 국세청
        <br/>
        -제공받는 자의 개인정보 이용목적: 세금계산서 발행
        <br/>
        -제공하는 항목: 회사명, 이름, 이메일, 사업장 소재지
        <br/>
        -제공받는 자의 보유·이용기간: 서비스가입일, 해지일 또는 제공계약 종료일 중 먼저 도래하는 시점
        <br/>
        2.금융결제원
        <br/>
        -개인정보를 제공받는 자: 금융결제원
        <br/>
        -제공받는 자의 개인정보 이용목적: CMS출금이체 서비스
        <br/>
        -제공하는 항목: 휴대전화번호, 생년월일, 은행계좌정보
        <br/>
        -제공받는 자의 보유·이용기간: 5년
        <br/>
        다만, 아래의 경우는 예외로 합니다.
        <br/>
        1.이용자들이 사전에 동의한 경우(이용자가 사전에 동의한 경우란, 서비스 이용 등을 위하여 이용자가 자발적으로 자신의 개인정보를 제3자에게 제공하는 것에 동의하는 것을 의미합니다.)
        <br/>
        2.법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        <br/>
        위의 경우에도, 회사는 이용자에게
        <br/>
        ①개인정보를 제공받는 자,
        <br/>
        ②그의 이용목적,
        <br/>
        ③제공되는 개인정보의 항목,
        <br/>
        ④개인정보의 보유 및 이용기간을 사전에 고지하고 이에 대해 명시적인 방법으로 개별적 동의를 얻습니다. 이와 같은 모든 과정에 있어서 회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를 벗어난 정보를 제3자와 공유하지 않습니다.
      </p>
      <h3 style="line-height: 40px">제5조 개인정보처리 위탁</h3>
      <p>
        회사는 모든 개인정보를 직접 관리하고 있습니다.
        <br/>
        1.회사는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독 하겠습니다.
        <br/>
        2.위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
      </p>
      <h3 style="line-height: 40px">제6조 정보주체의 권리·의무 및 그 행사방법</h3>
      <p>
        이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
        <br/>
        1.정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <br/>
        ①개인정보 열람요구
        <br/>
        ②오류 등이 있을 경우 정정 요구
        <br/>
        ③삭제요구
        <br/>
        ④처리정지 요구
        <br/>
        2.제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
        <br/>
        3.만 14세 미만 아동(이하 “아동”이라 함)의, 개인정보는 수집하지 않고 있습니다. 다만, 개인정보 수집이 필요한 경우 반드시 법정대리인의 동의를 구하고 있습니다.회사는 법정대리인의 동의를 받기 위하여 아동으로부터 법정대리인의 성명과 연락처 등 최소한의 정보를 요구할 수 있으며, 아동의 법정대리인은 서면, 전화, 이메일, 팩스 등을 통하여 아동의 개인정보에 대한 열람, 정정 및 삭제를 요청할 수 있으며, 이러한 요청이 있는 경우 회사는 지체없이 필요한 조치를 취합니다.
        <br/>
        4.정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
        <br/>
        5.제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        <br/>
        6.개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        <br/>
        7.개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br/>
        8.회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다
      </p>
      <h3 style="line-height: 40px">제7조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3>
      <p>
        회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 암호화된 쿠키를 사용합니다.
        <br/>
        1.쿠키 등 사용 목적
        <br/>
        회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공을 위해 사용됩니다. 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        <br/>
        2.쿠키 설정 거부 방법
        <br/>
        쿠키 설정을 거부하는 방법으로는 회원이 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.설정방법: (예: 인터넷 익스플로어의 경우) 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보(단, 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.)
      </p>
      <h3 style="line-height: 40px">제8조 개인정보 파기</h3>
      <p>
        회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다.
        파기의 절차, 기한 및 방법은 다음과 같습니다.
        <br/>
        -파기절차
        <br/>
        이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
        <br/>
        -파기기한
        <br/>
        이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
        <br/>
        -파기방법
        <br/>
        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        또한, 회사는 '개인정보 유효기간제'에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관 또는 삭제하고 있으며, 분리보관된 개인정보는 4년간 보관 후 지체없이 파기합니다.
      </p>
      <h3 style="line-height: 40px">제9조 개인정보의 안전성 확보 조치</h3>
      <p>
        회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적·관리적 및 물리적 조치를 하고 있습니다.
        <br/>
        1.정기적인 자체 감사 실시
        <br/>
        개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
        <br/>
        2.개인정보 취급 직원의 최소화 및 교육
        <br/>
        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
        <br/>
        3.내부관리계획의 수립 및 시행
        <br/>
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
        <br/>
        4.해킹 등에 대비한 기술적 대책
        <br/>
        회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로 감시 및 차단하고 있습니다.
        <br/>
        5.개인정보의 암호화
        <br/>
        이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
        <br/>
        6.접속기록의 보관 및 위변조 방지
        <br/>
        개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관·관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
        <br/>
        7.개인정보에 대한 접근 제한
        <br/>
        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>

        8.문서보안을 위한 잠금장치 사용
        <br/>
        개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
        <br/>
        9.비인가자에 대한 출입 통제
        <br/>
        개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
      </p>
      <h3 style="line-height: 40px">제10조 개인정보 보호책임자 작성</h3>
      <p>
        1.회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 및 관리자를 지정하고 있습니다.
        <br/><br/>
        ▶ 개인정보 보호관리자
        <br/>
        개인정보보호관리자 성명 : 이창하
        <br/>
        이메일 : admin@weselpay.com
        <br/><br/>
        2.정보주체께서는 회사의 주서비스 및 부가서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 운영지원본부로 문의하실 수 있습니다.회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
      </p>
      <h3 style="line-height: 40px">제11조 개인정보 열람청구</h3>
      <p>
        1.정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
        <br/><br/>
        ▶ 개인정보 열람청구 접수
        <br/>
        이메일 : admin@weselpay.com
        <br/><br/>
        2.정보주체께서는 제1항의 열람청구 접수․처리부서 이외에, 행정안전부의 ‘개인정보보호 종합지원 포털’ 웹사이트( )를 통하여서도 개인정보 열람청구를 하실 수 있습니다.
      </p>
      <h3 style="line-height: 40px">제12조 정보주체의 권익침해에 대한 구제방법</h3>
      <p>
        아래의 기관은 당사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        <br/>
        ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <br/>
        소관업무: 개인정보 침해사실 신고, 상담 신청
        <br/>
        홈페이지: privacy.kisa.or.kr
        <br/>
        전화: (국번없이) 118
        <br/>
        주소: (05717) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
        <br/>
        ▶ 개인정보 분쟁조정위원회(개인정보보호위원회 운영)
        <br/>
        소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
        <br/>
        홈페이지: www.kopico.go.kr
        <br/>
        전화: (국번없이) 1833-6972
        <br/>
        주소: (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층
        <br/>
        ▶ 대검찰청 사이버수사과 : (국번없이) 1301 ( )
        <br/>
        ▶ 경찰청 사이버안전국 : (국번없이) 182 (http://cyberbureau.police.go.kr)
      </p>
      <h3 style="line-height: 40px">제13조 개인정보 처리방침 고지의 의무</h3>
      <p>
        이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
        <br/>
        -개인정보 처리방침 시행일자 : 2022. 01. 01
        <br/>
        -개인정보 처리방침 변경공고일자 : 2022. 01. 01
        <br/>
      </p>


  </div>
</template>

<script>
export default {
  name: "TermsOfPrivacyPolicy"
}
</script>

<style scoped>

</style>