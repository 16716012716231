<template>
    <div>
      <h2 style="padding-bottom: 15px">전자금융거래 이용약관</h2>
      <p>
        전자금융거래 이용에 관한 기본약관
      </p>
      <h3 style="line-height: 40px">제1조 (목적)</h3>
      <p>
        이 약관은 씨에이치 플래닛(이하 "회사"라 함)가 제공하는 전자금융거래에 대한 기본적인 사항을 정함으로써 전자금융거래를 이용하고자 하는 이용자와 회사간의 권리,의무 관계를 명확하게 정함을 목적으로 한다.
      </p>
      <h3 style="line-height: 40px">제2조(정의)</h3>
      <p>
        1.이 약관에서 사용하는 용어의 정의는 다음과 같다.
        <br/>
        ①“전자금융거래”란 회사가 전자적 장치를 통하여 금융상품 및 서비스를 제공하고 고객이 회사와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말한다.
        <br/>
        ②“고객”이란 전자금융거래를 위하여 회사와 체결한 계약(이하 “전자금융거래계약“이라 한다)에 따라 전자금융거래를 이용하는 자를 말한다.
        <br/>
        ③“전자지급거래”란 자금을 주는 자(이하 “지급인”이라 한다)가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자(이하 “수취인”이라 한다)에게 자금을 이동하게 하는 전자금융거래를 말한다.
        <br/>
        ④“전자적 장치”란 전자금융 거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 전화기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말한다.
        <br/>
        ⑤“전자문서”란 전자거래기본법 제2조 제1호의 규정에 따른 작성, 송신.수신 또는 저장된 정보를 말한다.
        <br/>
        ⑥“접근매체”란 전자금융거래에 있어서 고객이 거래지시를 하거나 또는 고객 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 다음 각 목의 어느 하나에 해당하는 수단 또는 정보를 말한다.
        <br/>
        가.전자식 카드 및 이에 준하는 전자적 정보
        <br/>
        나.전자서명법 제2조 제4호의 전자서명생성정보 및 같은 조 제7호의 인증서
        <br/>
        다.회사에 등록된 고객번호
        <br/>
        라.고객의 생체정보
        <br/>
        마.가목 또는 나목의 수단이나 정보를 사용하는데 필요한 비밀번호
        <br/>
        ⑦“전자지급수단”이란 전자자금이체, 직불전자지급수단, 선불전자지급수단, 전자화폐, 신용카드, 전자채권 그 밖의 전자적 방법에 따른 지급수단을 말한다.
        <br/>
        ⑧“전자자금이체”란 지급인과 수취인 사이에 자금을 지급할 목적으로 회사에 개설된 계좌에서 다른 계좌로 전자적 장치에 의하여 다음 각 목의 어느 하나에 해당하는 방법으로 자금을 이체하는 것을 말한다.
        <br/>
        가.회회사는 제1항의 규정에 시
        <br/>
        나.회사에 대한 수취인의 추심지시(이하 “추심이체”라 한다)
        <br/>
        ⑨“거래지시”란 고객이 전자금융거래계약에 따라 회사에 전자금융거래의 처리를 지시하는 것을 말한다.
        <br/>
        ⑩“오류”란 고객의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 고객의 거래지시에 따라 이행되지 아니한 경우를 말한다.
        <br/>
        ⑪“개별약관”이라 함은 이 약관과 함께 전자금융거래에 적용되는 약관으로서 회사가 별도로 작성한 약관을 말한다.
        <br/>
        2.이 약관에서 별도로 정하지 아니한 용어의 정의는 전자금융거래법 및 전자금융거래법 시행령, 금융감독위원회의 전자금융감독규정 및 전자금융감독규정 시행세칙에서 정하는 바에 따른다.
      </p>
      <h3 style="line-height: 40px">제3조(전자금융거래계약의 체결 및 해지)</h3>
      <p>
        1.전자금융거래를 이용하고자 하는 고객은 개별약관에서 정하는 방법에 따라 회사와 전자금융거래계약을 체결하여야 한다.
        <br/>
        2.회사는 제1항의 규정에 의한 전자금융거래계약을 체결함에 있어 고객에게 약관을 명시하여야 하며, 고객의 요청이 있는 경우 다음 각 호의 어느 하나에 해당하는 방법으로 고객에게 약관을 교부하여야 한다.
        <br/>
        ①직접교부
        <br/>
        ②전자문서의 전송(전자우편을 이용한 전송을 포함한다)
        <br/>
        ③모사전송
        <br/>
        ④우편
        <br/>
        3.회사는 제1항의 규정에 의한 전자금융거래계약을 체결함에 있어 이용자가 약관의 내용에 대한 설명을 요청하는 경우 다음 각 호의 어느 하나에 해당하는 방법으로 고객에게 약관의 중요내용을 설명하여야 한다.
        <br/>
        ①약관의 중요내용을 고객에게 직접 설명
        <br/>
        ②약관의 중요내용에 대한 설명을 전자적 장치를 통하여 고객이 알기 쉽게 표시하고 고객으로부터 해당 내용을 충분히 인지하였다는 의사표시를 전자적 장치를 통하여 수령
        <br/>
        4.고객은 개별약관에서 정하는 방법에 따라 전자금융거래계약을 해지할 수 있다.
      </p>
      <h3 style="line-height: 40px">제4조(이용시간 등)</h3>
      <p>
        1.고객은 회사가 정한 이용시간 이내에서 전자금융거래를 이용할 수 있다.
        <br/>
        2.회사는 제1항의 규정에 의한 이용시간을 영업점 또는 전자적 장치에 게시하며, 이를 변경하고자 하는 경우 영업점 또는 전자적 장치에 변경 예정일 15일 전부터 1월 이상 비치 또는 게시한다. 다만, 시스템 장애복구, 프로그램의 긴급한 보수, 외부요인 등으로 인하여 이용시간이 변경된 경우에는 그러하지 아니하다.
        <br/>
        3.전산시스템 유지 및 보수로 인하여 전자금융거래 이용이 불가능할 경우 회사는 동 사실을 7일 전에 영업점 또는 전자적 장치에 게시한다.
      </p>
      <h3 style="line-height: 40px">제5조(수수료)</h3>
      <p>
        1.회사는 전자금융거래와 관련된 각종 수수료를 고객으로부터 징수할 수 있다.
        <br/>
        2.회사는 제1항의 규정에 의한 수수료의 징수기준을 영업점 또는 전자적 장치에 비치 또는 게시하며, 이를 변경하고자 하는 경우 영업점 또는 전자적 장치에 변경 예정일 15일 전부터 1월 이상 비치 또는 게시한다.
      </p>
      <h3 style="line-height: 40px">제6조(거래내용의 확인)</h3>
      <p>
        1.회사는 고객이 전자금융거래에 사용하는 전자적 장치(회사와 고객 사이에 미리 약정한 전자적 장치가 있는 경우에는 그 전자적 장치를 포함한다)를 통하여 거래내용을 확인할 수 있도록 한다. 이 경우 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 확인하게 할 수 없는 때에는 회사는 인터넷 등을 이용하여 즉시 그 사유를 알리고, 그 사유가 종료된 때부터 고객이 거래내용을 확인할 수 있도록 한다.
        <br/>
        2.고객은 거래지시와 제1항의 규정에 의한 거래내용이 일치하는지 여부를 확인하여야 한다.
        <br/>
        3.고객은 거래내용을 서면(전자문서를 제외한다. 이하 이조에서 같다.)으로 제공하여 줄 것을 회사에 요청할 수 있으며, 회사는 그 요청을 받은 날로부터 2주 이내에 거래내용에 관한 서면을 교부한다. 이 경우 거래내용의 서면 제공과 관련한 요청방법. 절차, 접수창구의 주소(전자우편주소를 포함한다) 및 전화번호 등은 개별약관에서 정한 바에 따른다.
        <br/>
        4.회사는 제3항의 규정에 의하여 고객으로부터 거래내용을 서면으로 제공할 것을 요청받은 경우 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 제공할 수 없는 때에는 그 고객에게 즉시 이를 알린다. 이 경우 거래내용에 관한 서면의 교부기간을 산정함에 있어서 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 제공할 수 없는 기간은 이를 산입하지 아니한다.
        <br/>
        5.회사가 제1항 및 제3항의 규정에 의하여 제공하는 거래내용의 종류(조회거래는 제외한다. 이하 이 조에서 같다.), 범위 및 대상기간은 다음 각 호와 같다.
        <br/>
        ①전자금융거래가 계좌를 통하여 이루어지는 경우 거래계좌의 명칭 또는 번호 : 5년
        <br/>
        ②전자금융거래1.의 종류 및 금액, 전자금융거래의 상대방에 관한 정보 : 5년
        <br/>
        ③전자금융거래의 거래일시, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보 : 5년
        <br/>
        ④회사가 전자금융거래의 대가로 받은 수수료 : 5년
        <br/>
        ⑤추심이체시 지급인의 출금동의에 관한 사항 : 5년
        <br/>
        ⑥.오류정정 요구사실 및 처리결과에 관한 사항 : 1년
        <br/>
        ⑦전자금융거래 신청, 조건변경에 관한 내용 : 5년
      </p>
      <h3 style="line-height: 40px">제7조(오류의 정정)</h3>
      <p>
        1.고객은 전자금융거래에 오류가 있음을 안 때에는 회사에게 이에 대한 정정을 요구할 수 있다.
        <br/>
        2.회사는 제1항의 규정에 의한 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리하며 정정요구를 받은 날부터 2주 이내에 그 결과를 고객에게 통보한다.
        <br/>
        3.회사가 전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리하며, 오류가 있음을 안 날로부터 2주 이내에 그 결과를 고객에게 통보한다.
      </p>
      <h3 style="line-height: 40px">제8조(회사의 책임)</h3>
      <p>
        1.회사는 접근매체의 위조나 변조로 발생한 사고, 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 고객에게 손해가 발생한 경우 그 손해를 배상한다.
        <br/>
        2.제1항에 불구하고 다음 각 호의 어느 하나에 해당하는 사유로 인하여 이용자에게 발생한 손해에 대하여는 고객이 그 책임의 전부 또는 일부를 부담하여야 한다.
        <br/>
        ①고객이 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공한 경우
        <br/>
        ②제3자가 권한 없이 고객의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 고객이 자신의 접근매체를 누설 또는 노출하거나 방치한 경우
        <br/>
        ③법인(중소기업기본법 제2조 제2항에 의한 소기업을 제외한다)인 고객에게 손해가 발생한 경우로 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 주의의무를 다한 경우
        <br/>
        ④천재지변, 전쟁, 테러 또는 회사의 귀책사유 없이 발생한 정전, 화재, 건물의 훼손 등 불가항력으로 인한 경우
        <br/>
        3.회사는 고객으로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용하여 고객에게 발생한 손해를 배상한다.
        <br/>
        4.회사는 제1항 내지 제3항의 규정에도 불구하고 다른 법령에 고객에게 유리하게 적용될 수 있는 규정이 있는 경우 그 법령을 우선 적용한다.
      </p>
      <h3 style="line-height: 40px">제9조(전자금융거래 장애시의 처리)</h3>
      <p>
        1.회사는 천재지변, 정전, 화재, 건물훼손, 전산장애 등의 사유로 고객의 거래지시를 처리할 수 없거나 처리가 지연될 경우 동 사실과 사유, 대체주문방법 등을 고객에게 지체없이 통보하며, 정상적인 전자금융거래가 이루어질 수 있도록 신속하게 조치한다.
        <br/>
        2.제1항의 규정에 의한 대체주문방법은 개별약관에서 정한 바에 따른다.
      </p>
      <h3 style="line-height: 40px">제10조(전자지급거래의 효력발생시기 등)</h3>
      <p>
        1.전자지급수단을 이용하여 자금을 지급하는 경우 지급의 효력은 다음 각 호에서 정한 때에 발생한다.
        <br/>
        ①전자자금이체의 경우 : 거래지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 계좌의 원장에 입금기록이 끝난 때
        <br/>
        ②전자적장치로부터 직접 현금을 출금하는 경우 : 수취인이 현금을 수령한 때
        <br/>
        ③선불전자지급수단 및 전자화폐로 지급하는 경우 : 거래지시된 금액의 정보가 수취인이 지정한 전자적 장치에 도달한 때
        <br/>
        ④그 밖의 전자지급수단으로 지급하는 경우 : 거래지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때
        <br/>
        2.고객은 제1항 각 호의 규정에 따라 지급의 효력이 발생하기 전까지 거래지시를 철회할 수 있다.
        <br/>
        3.제2항에 불구하고 회사는 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 개별약관에서 정한 바에 따라 거래지시의 철회시기를 달리 정할 수 있다.
        <br/>
        4.제2항 및 제3항의 규정에 의한 거래지시의 철회방법과 절차는 개별약관에서 정하는 바에 따른다.
      </p>
      <h3 style="line-height: 40px">제11조(추심이체의 출금 동의 등)</h3>
      <p>
        1.회사는 추심이체를 실행하기 위하여 다음 각 호의 어느 하나에 해당하는 방법으로 미리 지급인으로부터 출금에 대한 동의를 얻어야 한다.
        <br/>
        ①회사가 지급인으로부터 서면(공인전자서명이 있는 전자문서를 포함한다. 이하 이조에서 같다)에 의하여 출금신청을 받는 방법
        <br/>
        ②수취인이 지급인으로부터 서면에 의한 출금의 동의를 받아 회사에게 전달(전자적인 방법에 의하여 출금동의의 내역을 전송하는 것을 포함한다)하는 방법
        <br/>
        2.지급인은 수취인의 거래지시에 따라 지급인의 계좌의 원장에 출금기록이 끝나기 전까지 회사에게 제1항의 규정에 의한 동의의 철회를 요청할 수 있다.
        <br/>
        3.제2항의 규정에도 불구하고 회사는 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 개별약관에서 정한 바에 따라 동의의 철회시기를 달리 정할 수 있다.
        <br/>
        4.제2항 및 제3항의 규정에 의한 동의의 철회방법과 절차는 개별약관에서 정하는 바에 따른다.
      </p>
      <h3 style="line-height: 40px">제12조(접근매체의 사용 등)</h3>
      <p>
        1.고객은 전자금융거래에 있어 전자서명법에 의한 공인인증서를 사용하여야 한다. 다만, 다음 각 호의 어느 하나에 해당하는 거래에 대하여는 그러하지 아니하다.
        <br/>
        ①고객 본인 계좌에 대한 조회
        <br/>
        ②전화, 자동화기기(CD/ATM) 등과 같이 공인인증서의 설치.운용이 불가능한 전자적 수단을 이용한 전자금융거래
        <br/>
        ③등록금, 원서접수비 등 본인확인이 가능하고 입금계좌가 지정되어 있는 경우
        <br/>
        ④전자금융감독규정 시행세칙 제31조 제6호의 규정에 의해 금융감독원장이 승인하는 경우
        <br/>
        2.고객은 다음의 경우를 제외하고는 전자자금이체시 일회용비밀번호(보안카드를 포함한다)를 사용하여야 한다.
        <br/>
        ①자동화기기(CD/ATM)를 이용한 자금이체
        <br/>
        ②회사의 제휴금융기관에서 실명 확인 후 개설된 증권계좌와 실명 확인된 본인명의 계좌로 이체하는 경우
        <br/>
        ③회사에 방문하여 등록한 실명확인된 본인 명의 계좌로 이체하는 경우
        <br/>
        ④전자금융감독규정 시행세칙 제29조 제1항 제6호의 규정에 의해 금융감독원장이 승인하는 경우
        <br/>
        3.고객은 접근매체를 제3자에게 양도.양수하거나 질권을 설정하여서는 아니된다.
        <br/>
        4.고객은 접근매체를 제3자에게 누설하여서는 아니되며, 접근매체의 분실, 도용, 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 한다.
      </p>
      <h3 style="line-height: 40px">제13조(신고사항의 변경 및 사고신고)</h3>
      <p>
        1.고객이 주소(전자우편주소를 포함한다), 전화번호, 각종 비밀번호 등 회사에 신고한 사항을 변경하고자 하는 경우 개별약관에서 정하는 방법에 따라 회사에 신고하여야 한다.
        <br/>
        2.고객은 접근매체 등 전자금융거래에 있어 비밀을 요하는 사항이 도난, 분실, 위조, 변조 또는 누설되었음을 알았을 때에는 지체없이 이를 개별약관에서 정한 방법에 따라 회사에 신고하여야 한다.
      </p>
      <h3 style="line-height: 40px">제14조(약관의 변경 등)</h3>
      <p>
        1.회사는 약관을 변경하고자 하는 경우 시행일 1월 전에 변경되는 약관을 전자금융거래를 수행하는 전자적 장치(해당 전자적 장치에 게시하기 어려운 경우에는 고객이 접근하기 용이한 전자적 장치로서 회사가 지정하는 대체장치를 포함한다. 이하 이조에서 같다.)에 게시하고 고객에게 전자문서 등 고객과 사전에 합의된 방법으로 통지하여야 한다. 다만, 고객이 이의를 제기할 경우 회사는 고객에게 적절한 방법으로 약관 변경내용을 통지하였음을 확인해 주어야 한다.
        <br/>
        2.제1항에 불구하고 회사가 법령의 개정으로 인하여 긴급하게 약관을 변경하는 때에는 변경된 약관을 전자적 장치에 1개월 이상 게시하고 제1항에서 정한 방법으로 고객에게 통지하여야 한다.
        <br/>
        3.고객은 제1항 및 제2항의 규정에 따라 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 전자금융거래계약을 해지할 수 있다.
        <br/>
        4.회사는 제3항의 규정에 의한 기간동안 고객이 약관의 변경내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로 본다.
        <br/>
        5.회사는 고객이 약관의 교부를 요청하는 경우 이에 응하여야 하며, 전자금융거래를 수행하는 전자적 장치에 게시하여 고객이 약관을 조회하고 다운로드(화면출력을 포함한다)받을 수 있도록 한다.
      </p>
      <h3 style="line-height: 40px">제15조(고객정보에 대한 비밀보장)</h3>
      <p>
        회사는 관계법령에서 정한 경우를 제외하고는 전자금융거래를 수행함에 있어 알게 된 다음 각 호의 어느 하나에 해당하는 고객정보를 고객의 동의를 얻지 아니하고 타인에게 제공하거나 업무상 목적 외에 사용하지 아니한다.
        <br/>
        1.고객의 인적사항
        <br/>
        2.고객의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료
      </p>
      <h3 style="line-height: 40px">제16조(분쟁처리 및 분쟁조정)</h3>
      <p>
        1.고객은 전자금융거래의 처리에 관하여 이의가 있을 때에는 회사에 손해배상 등 분쟁처리를 요구하거나 금융감독원의 금융분쟁조정위원회 또는 한국소비자보호원의 소비자분쟁조정위원회, 한국금융투자협회 등을 통하여 분쟁조정을 신청할 수 있다.
        <br/>
        2.회사는 손해배상 등 분쟁처리를 위한 분쟁처리책임자 및 담당자를 지정하며, 그 연락처(전화번호,모사전송번호,전자우편주소 등을 말한다)를 인터넷 등을 통하여 고객에게 알린다.
        <br/>
        3.제1항에 따라 고객이 회사에 손해배상 등 분쟁처리를 신청한 경우 회사는 15일 이내에 이에 대한 조사 또는 처리결과를 고객에게 통보한다.
      </p>
      <h3 style="line-height: 40px">제17조(약관적용의 우선순위 등)</h3>
      <p>
        1.전자금융거래에 관해서는 이 약관을 우선 적용하며, 이 약관에서 정하지 않은 사항은 개별약관 및 전자금융거래법 등 관계법령이 정하는 바에 따른다.
        <br/>
        2.회사와 고객간에 개별적으로 합의한 사항이 이 약관에서 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용한다.
        <br/>
      </p>
      <h3 style="line-height: 40px">제18조 (조사 협조 등) 고객은 제8조 제1항의 규정에 의한 사고로 인한 손해배상 등 분쟁처리와 관련한 회사의 사고조사와 관계당국의 수사 또는 조사절차에 협조하여야 한다</h3>
      <h3 style="line-height: 40px">제19조(준거법) 약관의 해석 및 적용에 관하여는 대한민국법을 적용한다.</h3>
      <h3 style="line-height: 40px">부 칙</h3>
      <p>
        이 약관은 2022년 1월 1일부터 시행한다.
      </p>
    </div>
</template>

<script>
export default {
  name: "TermsOfElectronicFinancialTrans"
}
</script>

<style>
</style>